<template>
  <div>
    <b-container fluid class="mb-4">
      <b-row align-h="center" class="py-3 py-sm-4 py-md-5">
        <b-col class="col-auto col-sm-9 col-lg-6 pt-lg-5">
          <b-card style="border-radius: 0" border-variant="white" class="mb-2">
            <h5 class="text-medium font-weight-bold">
              {{ $t("page.questions.description") }}
            </h5>
          </b-card>
          <b-card
            :key="question._id"
            v-for="(question, i) of questions"
            class="mb-2"
            style="border-radius: 0"
            border-variant="white"
          >
            <div>
              <div class="py-2 font-weight-bold">
                {{ i + 1 }}. {{ question.text }}
              </div>
              <b-form-group v-if="question.type === 'Single choice'">
                <b-form-radio-group
                  v-model="answers[i].answer"
                  stacked
                  :options="question.answers.answerList"
                  :name="`single-${i}`"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group v-if="question.type === 'Multi choice'">
                <b-form-checkbox-group
                  stacked
                  v-model="answers[i].answer"
                  :options="question.answers.answerList"
                  name="multi"
                ></b-form-checkbox-group>
              </b-form-group>

              <b-form-textarea
                class="mt-3"
                trim
                rows="3"
                no-resize
                v-model="answers[i].answer"
                v-if="question.type === 'Open ended'"
                :placeholder="$t('enterYourAnswer')"
              ></b-form-textarea>

              <b-form-group
                class="custom-group"
                v-if="question.type === 'Scale'"
              >
                <b-form-radio-group
                  v-model="answers[i].answer"
                  :options="[1, 2, 3, 4, 5]"
                  :name="`scale-${i}`"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </b-card>
          <b-row class="mt-4" align-h="end">
            <b-col cols="auto">
              <b-button
                :disabled="!filled"
                @click="save()"
                class="px-3 py-2 text-uppercase"
                size="sm"
              >
                {{ $t("button.continue") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answers: [],
      questions: [],
      filled: true
    };
  },
  created() {
    this.questions = this.$store.getters["stimulus/getQuestions"];
    this.questions.forEach(question => {
      if (question.type === "Open ended") {
        this.answers.push({
          id: question._id,
          answer: ""
        });
      } else {
        this.answers.push({
          id: question._id,
          answer: []
        });
      }
    });
  },
  mounted(){
    window.addEventListener("beforeunload",this.handleBeforeUnload)
  },
  beforeDestroy(){
    window.removeEventListener("beforeunload",this.handleBeforeUnload);
  },

  watch: {
    answers: {
      handler() {
        let temp=true; 
        this.answers.forEach(answer => {
          if (answer.answer.length === 0) {
            temp = false;
          } 
        });
        this.filled=temp;
      },
      deep: true
    }
  },

  methods: {
    handleBeforeUnload(event){
      event.preventDefault();
      const confirmationMessage="Are you sure you want to leave?\nYour participation will not count if you leave now!"
      event.returnValue = confirmationMessage; // some browsers require returnValue to be set
      return confirmationMessage;
    },

    save() {
      window.removeEventListener("beforeunload",this.handleBeforeUnload);
      this.$store.commit("stimulus/SET_ANSWERS", this.answers);
      this.$store.commit("stimulus/SET_DONE", true);
      this.$store
        .dispatch("stimulus/uploadAnswers")
        .then(response => {
          this.$store.commit(
            "stimulus/SET_COMPLETE_LINK",
            response.endLinks.complete
          );
          this.$store.commit("stimulus/SET_UPLOAD_ANSWERS_COMPLETED", true);
          this.$router.push({
            name: "done",
            params: {
              pathMatch: this.$store.getters["settings/getEnvironment"]
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
.custom-group.form-group > .bv-no-focus-ring {
  display: flex !important;
  overflow: wrap;
}
</style>
